import React, { useState, useRef, useEffect } from 'react';

// Composant CodeInput
function CodeInput({
  fields,
  separator = '-',
  maxLength = 1,
  onChange = () => {},
  onClick = () => {},
}) {
  const [values, setValues] = useState(Array(fields).fill(''));
  const refs = useRef([]);

  useEffect(() => {
    // Fonction pour gérer les touches du clavier
    function handleKeyDown(event) {
      const currentRef = refs.current.find((ref) => document.activeElement === ref);
      if (!currentRef) {
        return;
      }

      // Gestion des flèches gauche et droite ainsi que de la touche Tab
      switch (event.key) {
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'Tab':
          const direction =
            event.key === 'ArrowLeft' || (event.key === 'Tab' && event.shiftKey)
              ? 'previousSibling'
              : 'nextSibling';
          let siblingRef = currentRef[direction];
          while (siblingRef && siblingRef.nodeName !== 'INPUT') {
            siblingRef = siblingRef[direction] ?? null;
          }
          if (siblingRef) {
            siblingRef.focus();
          }
          break;
        case 'Backspace':
          if (currentRef.value === '' && currentRef.previousSibling) {
            let prevRef = currentRef.previousSibling;
            while (prevRef && prevRef.nodeName !== 'INPUT') {
              prevRef = prevRef.previousSibling ?? null;
            }
            if (prevRef) {
              prevRef.focus();
            }
          }
          break;
        default:
          console.log(event.key);
          break;
      }
    }

    // Ajout de l'écouteur d'événements et nettoyage à la fin
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [fields]);

  // Fonction pour gérer les changements de valeur
  function handleChange(event, index) {
    let newValue = event.target.value;
    if (maxLength && newValue.length > maxLength) {
      if (!isNaN(Number(newValue))) {
        newValue = String(Number(newValue)).substring(0, maxLength);
      } else {
        newValue = newValue.substring(0, maxLength);
      }
    }

    const newValues = [...values];

    // Si la longueur de la nouvelle valeur est supérieure à la longueur maximale autorisée,
    // nous remplaçons la valeur du champ actuel par le dernier caractère de la nouvelle valeur.
    if (newValue.length > maxLength) {
      newValues[index] = newValue[newValue.length - 1];
    } else {
      newValues[index] = newValue;
    }

    setValues(newValues);

    if (newValue.length === maxLength && index < fields - 1) {
      refs.current[index + 1].focus();
    }
  }

  // Fonction pour gérer le collage de texte
  function handlePaste(event, index) {
    const pasteData = event.clipboardData.getData('text/plain');
    const pasteValues = pasteData.split('');

    const newValues = [...values];
    let i = index;
    pasteValues.forEach((value) => {
      if (i >= fields) {
        return;
      }

      if (maxLength && value.length > maxLength) {
        if (!isNaN(Number(value))) {
          value = String(Number(value)).substring(0, maxLength);
        } else {
          value = value.substring(0, maxLength);
        }
      }

      newValues[i] = value;
      i++;

      if (i < fields) {
        refs.current[i].focus();
      }
    });

    setValues(newValues);
  }
  onChange(values.join(''));
  return (
    <div>
      {values.map((value, i) => (
        <React.Fragment key={i}>
          <input
            type="number"
            value={value}
            onChange={(e) => handleChange(e, i)}
            onPaste={(e) => handlePaste(e, i)}
            ref={(el) => el && (refs.current[i] = el)}
          />
          {i < fields - 1 && separator !== 'none' && <span>{separator}</span>}
        </React.Fragment>
      ))}
      <span>{''}</span>
      {values.join('').length >= 6 ? (
        <button className={'sendBtnCode'} onClick={onClick}>
          Valider
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CodeInput;
