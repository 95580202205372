import React, { useEffect, useMemo, useState } from 'react';
import getAllFlagrance from './getAllFlagrance';
import ReactSearchBox from 'react-search-box';
import { useMediaQuery } from 'react-responsive';

// eslint-disable-next-line react/prop-types
const SearchBarFlagrance = ({
  allFlagrance,
  onSelected,
  placeholder = false,
  leftIcon = '',
  clearOnSelect = false,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [initData, setInitData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [fragranceSelected, setFragranceSelected] = useState('');
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const flagranceCustom = {
    brand: '',
    name: '',
    concentration: 'Eau de parfum',
    description: 'Musqué floral',
    img: '/pictogrammeParfum.png',
    value: 'Et voilà',
    createAt: '',
    id: -1,
  };

  useEffect(() => {
    setInitData(allFlagrance);
  }, [allFlagrance]);

  const dataFilterWeb = useMemo(() => {
    let value = searchValue;
    return (initData || [])
      .filter(
        (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.brand.toLowerCase().includes(value.toLowerCase()),
      )
      .map((fragrance) => ({ ...fragrance, value: `${fragrance.brand} - ${fragrance.name}` }));
    //.slice(0, 10);
  }, [initData, searchValue]);

  const onSearchChangeWeb = (value) => {
    setSearchValue(value);
  };

  const onSearchChange = (e) => {
    const value = e?.target?.value;
    setSearchValue(value);
  };

  const fuseConfigs = {
    keys: ['name', 'name'],
    threshold: 0.3,
    shouldSort: true,
    getFn: (obj) => `${obj.name} ${obj.brand}`,
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    filterData(value);
  };

  const filterData = (value) => {
    if (!!!value) {
      return setFilteredData([]);
    }
    const filtered = (initData || [])
      .filter(
        (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.brand.toLowerCase().includes(value.toLowerCase()),
      )
      .map((fragrance) => ({ ...fragrance, value: `${fragrance.brand} - ${fragrance.name}` }));

    setFilteredData(filtered);
  };

  if (isTabletOrMobile) {
    return (
      <div>
        <div
          style={{ padding: 10, backgroundColor: '#ffffff', borderRadius: 10, height: 52, flex: 1 }}
        >
          <input
            type="text"
            value={searchValue}
            onChange={handleChange}
            //placeholder={`${fragranceSelected?.brand || ''}`}
            placeholder={fragranceSelected ? `Modifier le parfum` : ''}
          />
        </div>
        <div style={{ height: 'auto', maxHeight: '300px', overflowY: 'auto', marginTop: 20 }}>
          <ul
            style={{
              gap: 10,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {filteredData.map((fragrance) => (
              <li
                onClick={(record) => {
                  onSelected({ ...fragrance, value: `${fragrance.brand} - ${fragrance.name}` });
                  setFragranceSelected(fragrance);
                  setSearchValue('');
                  setFilteredData([]);
                }}
                style={{
                  padding: 10,
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                  borderTopColor: 'gray',
                  borderBottomColor: 'gray',
                  backgroundColor: '#ffffff',
                  borderRadius: 5,
                }}
                key={fragrance.id}
              >
                {fragrance.brand} - {fragrance.name}
              </li>
            ))}
            {filteredData?.length === 0 && (
              <span style={{ color: '#ffffff' }}>🌸 Entrer le nom du parfum</span>
            )}
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ReactSearchBox
        inputW={'40px'}
        placeholder={placeholder ? placeholder : 'Nom du parfum'}
        data={dataFilterWeb}
        value={searchValue}
        onSelect={(record) => {
          onSelected(record.item);
          setSearchValue('');
        }}
        onFocus={() => {
          setSearchValue('');
        }}
        clearOnSelect={clearOnSelect}
        autoFocus
        leftIcon={<span> {leftIcon}</span>}
        iconBoxSize={leftIcon ? '48px' : 0}
        onChange={onSearchChangeWeb}
        fuseConfigs={fuseConfigs}
      />
    </div>
  );
};
export default SearchBarFlagrance;
