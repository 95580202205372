import React, { useEffect, useState } from 'react';
import { CCol, CContainer, CRow } from '@coreui/react';
import { useSwiper } from 'swiper/react';

import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { getProfil, updateMailChimpTagUser } from 'src/request';
const Step5 = () => {
  const swiper = useSwiper();
  const navigate = useNavigate();
  const mutation = useMutation(updateMailChimpTagUser);
  const [subscribed, setSubscribed] = useState(null);

  const {
    data: profil,
    isLoading: loadingLayerings,
    refetch: refetchLayrings,
    error,
  } = useQuery('profilStep5', getProfil);

  useEffect(() => {
    if (profil) setSubscribed(profil?.isSubscribed);
  }, [profil]);

  useEffect(() => {
    const res = mutation.mutate({ isFreeAccount: false });
  }, []);

  return (
    <div className={'Step5Register'}>
      <div style={{ marginTop: 40 }}>
        <img draggable={false} src={'icon/iconCongratulations.png'} alt={'icon congratulations'} />
        <p className="textImput" style={{ marginTop: 20 }}>
          Bienvenue !
        </p>
      </div>
      <p className="textImput fontSize19">Félicitations, votre inscription a bien été finalisée</p>
      <p className="  " style={{ textAlign: 'center', fontWeight: '600' }}>
        Vous venez d’offrir le meilleur cadeau à votre histoire olfactive.
      </p>
      <p style={{ textAlign: 'center', fontWeight: '600' }}>
        {`Prenez plaisir à le remplir, l'emmener partout avec vous mais aussi le relire. Ceci est votre cocon `}
        <p>
          olfactif ! Perfumista, créateur de contenu, élève en école de parfumerie ou parfumeur,
          chacun
          <p>peut y trouver confort et praticien</p>
        </p>
      </p>

      <CContainer fluid>
        <CRow className={'marginTop40'}>
          <CCol>
            <button
              style={{ padding: '5px', paddingBottom: '10px' }}
              className="buttonNext"
              onClick={() => {
                navigate('/');
              }}
            >
              Accéder à mon profil
            </button>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
export default Step5;
