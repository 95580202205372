const dataFetch = (getPromise) => {
  return {
    data: wrapPromise(getPromise),
  };
};
export class PromiseWrapper {
  constructor(promise) {
    this.status = 'pending';
    this.result = undefined;
    this.suspend = this.wrapPromise(promise);
  }

  wrapPromise(promise) {
    return promise().then(
      (res) => {
        this.status = 'success';
        this.result = res;
      },
      (err) => {
        this.status = 'error';
        this.result = err;
      },
    );
  }

  refresh(suspend) {
    if (this.status === 'pending') {
      return this;
    }
    this.wrapPromise(suspend);
    return this;
  }

  read() {
    if (this.status === 'pending') {
      throw this.suspend;
    } else if (this.status === 'error') {
      throw this.result;
    } else if (this.status === 'success') {
      return this.result;
    }
  }
}

const wrapPromise = (promise) => {
  let status = 'pending';
  let result;
  let suspend = promise().then(
    (res) => {
      status = 'success';
      result = res;
    },
    (err) => {
      status = 'error';
      result = err;
    },
  );
  return {
    refresh() {
      return wrapPromise(promise);
    },
    read() {
      if (status === 'pending') {
        throw suspend;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    },
  };
};

export default PromiseWrapper;
