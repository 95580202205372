import React from 'react';
import { useEffect, useState } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import {useStripe, useElements} from '@stripe/react-stripe-js';
import { CButton } from '@coreui/react';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:  props?.confirmParams ?? `${window.location.origin}/success`,        
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element"
        // Access the email value like so:
        // onChange={(event) => {
        //  setEmail(event.value.email);
        // }}
        //
        // Prefill the email field like so:
         options={{defaultValues: {email: String(props?.userProfil?.email)||''}}}
        />
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> :  <CButton                
                style={{
                  backgroundColor: '#22A18A',
                  display:'flex', 
                  alignSelf:"flex-end",
                  marginTop:20,
                  marginBottom:10
                }}
              >
                Payer
              </CButton>}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message" style={{fontWeight:"600", fontSize:18, color:"red"}}>{message}</div>}
    </form>
  )
}