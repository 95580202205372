import React, { useEffect, useState } from 'react';
import { CCol, CContainer, CRow } from '@coreui/react';
import InputIcon from '../../../../components/InputIcon';
import SelectIcon from '../../../../components/SelectIcon';
import { useSwiper } from 'swiper/react';
import { Link } from 'react-router-dom';
import SearchBarFlagrance from '../../../../components/SearchBarFlagrance';
import { getAllFragrance } from '../../../../request';

import PromiseWrapper from '../../../../components/WrapPromise';

let PromiseWrapperGetAllFragrance = new PromiseWrapper(getAllFragrance);
const Step3 = ({
  refForm,
  page = {
    goToLogin: () => {},
    goToSignUp: () => {},
  },
}) => {
  const swiper = useSwiper();
  const [ok, setOk] = useState(false);
  const [symbolic, setSymbolic] = useState(false);
  const [isErrorImg, setIsErrorImg] = useState(false);
  const handleImageError = () => {
    setIsErrorImg(true);
  };
  const promiseAllFragrance = PromiseWrapperGetAllFragrance.read();
  useEffect(() => {
    swiper.on('transitionEnd', () => {
      if (swiper.activeIndex === 1) {
        setOk(true);
      }
    });
  });
  console.log(swiper.activeIndex);
  return (
    <>
      <p className="textImput" onClick={() => setOk(true)}>
        Complétez votre profil
      </p>
      <CContainer fluid className={'marginRight15'}>
        <CRow>
          <CCol className={'paddingLeft0'}>
            <h5 className={'fontSize17 marginLeft15'}>En utilisant cet espace, je souhaite</h5>
            <InputIcon
              refCustom={refForm.pseudo}
              placeholder={'Améliorer mon olfaction'}
            ></InputIcon>
          </CCol>
        </CRow>

        <CRow className={'marginTop40'}>
          <CCol className={'paddingLeft0'}>
            <CCol className={'paddingLeft0'}>
              <h5 className={'fontSize17 marginLeft15'}>Ce que je pense de ma collection</h5>
              <SelectIcon iconLeft={'smileyFace.png'} iconRight={'chevronIcon.png'}>
                <option value="1">Satisfait</option>
                <option value="2">Insatisfait</option>
              </SelectIcon>
            </CCol>
          </CCol>
          <CCol className={'paddingLeft0'}>
            <div className={'signUpSearchBar'}>
              {ok ? <h5 className={'fontSize17 marginLeft15'}>Mon parfum symbolique</h5> : []}
              {ok && !symbolic ? (
                <SearchBarFlagrance
                  allFlagrance={promiseAllFragrance}
                  onSelected={(data) => {
                    setSymbolic(data);
                  }}
                ></SearchBarFlagrance>
              ) : (
                ''
              )}
              {symbolic ? (
                <div className={'symbolic'}>
                  <button onClick={() => setSymbolic(false)}>X</button>
                  <img
                    onError={handleImageError}
                    src={isErrorImg ? '/pictogrammeParfum.png' : symbolic.img}
                  />
                  <p className={'nameFragrance'}>
                    {symbolic.brand} {symbolic.name}
                    <span className={'flagranceType'}>
                      <br />
                      {symbolic.value} • {symbolic.concentration}
                    </span>
                  </p>
                </div>
              ) : (
                []
              )}
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol className={'paddingLeft0'}>
            <h5 className={'paddingLeft20 marginTop13'}>Les odeurs de mon enfance</h5>
            <textarea
              className={'inputOdeursEnfance'}
              placeholder={'Décrivez les odeurs de votre enfance...'}
            ></textarea>
          </CCol>
        </CRow>
        <CRow className={'marginTop40'}>
          <CCol className={'paddingLeft0'}>
            <button
              className="buttonPrev"
              onClick={() => {
                swiper.slidePrev();
              }}
            >
              Retour
            </button>
            <button
              className="buttonNext"
              onClick={() => {
                swiper.slideNext();
              }}
            >
              Suivant
            </button>
          </CCol>
        </CRow>
      </CContainer>
      <p className={'textBottom'}>
        Vous avez déjà un compte ?{' '}
        <button onClick={() => page.goToLogin()} className={'textBottomLink'}>
          Connectez-vous pour y accéder !
        </button>
      </p>
    </>
  );
};
export default Step3;
