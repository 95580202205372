import React, { useEffect, useState } from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
} from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { getInvoices } from 'src/request';
import { useQuery } from 'react-query';

const ModalLimitContent = ({ visible, onClose, showButton, content, navigateTo }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(
    'Oh, il semblerait que vous ayez atteint la limite de fiches créées dans votre carnet olfactif digital. En effet, vous disposez actuellement de la version découverte, qui vous limite à 5 fiches par section. Si vous souhaitez profiter pleinement de votre carnet, démarrez votre abonnement dès maintenant',
  );

  const {
    data,
    isLoading: isLoadingInvoices,
    refetch: refetchInvoices,
    error,
  } = useQuery('invoices', getInvoices);

  useEffect(() => {
    if (data?.unpaidInvoices?.length > 0) {
      setMessage(
        "Oups, un léger problème de paiement sur votre dernière facture nous empêche de vous permettre d'ajouter de nouveaux contenus. Dès que la facture sera réglée, vous retrouverez tous les avantages de votre carnet olfactif. Sans action de votre part, votre compte restera en offre découverte",
      );
    }
  }, [data]);

  return (
    <CModal visible={visible} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>Oupss !</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>{content ?? message}</p>
      </CModalBody>
      {showButton && (
        <CModalFooter>
          <CButton onClick={onClose} style={{ backgroundColor: '#22A18A' }}>
            {'Voir les offres'}
          </CButton>
        </CModalFooter>
      )}
    </CModal>
  );
};

export default ModalLimitContent;
