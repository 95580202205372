import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import Login from '../Login/Index.js';
import SignUp from './Index.js';

const Signup = () => {
  return (
    <div className={'containerLog'}>
      <Swiper spaceBetween={100} speed={440}>
        <SwiperSlide>
          <SignUp />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Signup;
