// @ts-ignore
import React from 'react';
import classnames from 'classnames';
const SelectIcon = ({
  iconLeft = null,
  iconRight = null,
  containerStyle = '',
  inputStyle = '',
  children = null,
  containerImgStyle = '',
  onChange = (event) => {},
  refCustom = undefined,
}) => {
  return (
    <div className={classnames('containerRelative', containerStyle)}>
      <select
        onChange={(event) => {
          onChange(event);
        }}
        ref={refCustom}
        className={classnames('inputGrey', 'selectIcon', inputStyle)}
      >
        {children}
      </select>
      {iconLeft ? (
        <div className={classnames('imgInputIcon', containerImgStyle)}>
          <img src={`icon/${iconLeft}`} alt={'icon'}></img>
        </div>
      ) : null}

      {iconRight ? (
        <div className={classnames('iconRight', containerImgStyle)}>
          <img src={`icon/${iconRight}`} alt={'icon'}></img>
        </div>
      ) : null}
    </div>
  );
};

export default SelectIcon;
