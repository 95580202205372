import React, { useState } from 'react';
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CForm,
  CFormInput,
  CFormFeedback,
  CInputGroup,
  CCol,
  CCardTitle,
  CCardSubtitle,
} from '@coreui/react';
import { lostPassword } from 'src/request';

const PasswordResetModal = ({ visible, onClose }) => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLostPwd = () => {
    setLoading(true);
    lostPassword({ email })
      .then((res) => {
        setSuccessMessage('Un email a été envoyé pour réinitialiser votre mot de passe.');
        setTimeout(() => {
          setSuccessMessage('');
          onClose();
        }, 3000); // Fermeture de la modal après 3 secondes
      })
      .catch((err) => {
        setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <CModal visible={visible} onClose={onClose}>
      <CModalHeader style={{ flexDirection: 'column', flexDirection: 'column' }} onClose={onClose}>
        <CCardTitle>Réinitialiser le mot de passe</CCardTitle>
      </CModalHeader>
      <CModalBody>
        <CForm>
          <CCol md={12}>
            <CInputGroup className="mb-3">
              <CFormInput
                type="email"
                placeholder="Votre adresse mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <CCardSubtitle className="mt-2">
                {
                  "Si vous ne voyez pas l'e-mail de réinitialisation dans votre boîte de réception, pensez à vérifier vos mails indésirables"
                }
              </CCardSubtitle>
              <CFormFeedback invalid>Veuillez saisir une adresse email valide.</CFormFeedback>
            </CInputGroup>
          </CCol>
          {errorMessage && <CFormFeedback className="d-block">{errorMessage}</CFormFeedback>}
          {successMessage && <CFormFeedback className="d-block">{successMessage}</CFormFeedback>}
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Annuler
        </CButton>
        <CButton color="primary" onClick={handleLostPwd} disabled={loading}>
          {loading ? 'Envoi...' : 'Envoyer'}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default PasswordResetModal;
