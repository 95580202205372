import React from 'react';
import classnames from 'classnames';
const InputIcon = ({
  iconLeft = null,
  iconRight = null,
  containerStyle = '',
  inputStyle = '',
  containerImgStyle = '',
  refCustom = undefined,
  placeholder = undefined,
  type = undefined,
  iconLeftOnclick = () => {},
  iconRightOnclick = () => {},
  onChange = (event, prevValue) => {},
}) => {
  const [valueInput, setValueInput] = React.useState('');
  return (
    <div className={classnames('containerRelative', containerStyle)}>
      <input
        ref={refCustom}
        value={valueInput}
        onChange={(event) => {
          onChange(event, valueInput);
          setValueInput(event.target.value);
        }}
        type={type}
        className={classnames('inputGrey', inputStyle)}
        placeholder={placeholder}
      />
      {iconLeft ? (
        <div className={classnames('imgInputIcon', containerImgStyle)}>
          <img onClick={iconLeftOnclick} src={`/icon/${iconLeft}`} alt={'icon'}></img>
        </div>
      ) : null}

      {iconRight ? (
        <div className={classnames('iconRight', containerImgStyle)}>
          <img onClick={iconRightOnclick} src={`/icon/${iconRight}`} alt={'icon'}></img>
        </div>
      ) : null}
    </div>
  );
};

export default InputIcon;
