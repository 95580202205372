import React, { useEffect, useState } from 'react';
import {
  CCol,
  CContainer,
  CRow,
  CForm,
  CFormInput,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react';
import { useSwiper } from 'swiper/react';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { changeSubscription } from 'src/request';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from 'src/views/abonnement/CheckoutForm';
import { stripePromise } from 'src/App';

const ExpiryInput = ({ id, className, delimiter, placeholder, onChange = (val) => {} }) => {
  const correctInput = (event) => {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/', // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/', // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2', // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        '$1/$2', // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0', // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        '', // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/', // Prevent entering more than 1 `/`
      );
    return event.target.value;
  };

  return (
    <CFormInput
      type="text"
      placeholder="MM/YY"
      maxLength="5"
      label="Expiration"
      required
      style={{ width: '80%' }}
      onKeyUp={(event) => {
        let val = correctInput(event);
        onChange(val);
      }}
    />
  );
};

const CardSelected = ({ callBack = () => {} }) => {
  const [active, setActive] = useState(860);
  useEffect(() => {
    callBack(active);
  }, [active]);
  useEffect(() => {
    callBack(active);
  }, []);

  return (
    <div className="containerSubscriptionCreditCards">
      <div
        className={'subscription ' + (active === 860 ? 'active' : '')}
        onClick={() => setActive(860)}
      >
        <h2>Abonnement Trimestriel</h2>
        <p className="price">
          <span
            style={{
              fontSize: '22px',
              position: 'relative',
              top: '-17px',
              left: '180px',
              color: active === 860 ? '#ffe86cf0' : 'gray',
            }}
          >
            <span
              style={{
                fontSize: '22px',
                textDecoration: 'line-through',
                color: active === 860 ? '#ffe86cf0' : 'gray',
              }}
            >
              29.99
            </span>
            €
          </span>
          <span>26.99€</span>/ mois
        </p>
        <p className="message">Renouvellement automatique tous les 3 mois</p>
      </div>

      <div
        className={'subscription ' + (active === 990 ? 'active' : '')}
        onClick={() => setActive(990)}
      >
        <h2>Abonnement Mensuel</h2>
        <p className="price">
          <span
            style={{
              fontSize: '22px',
              position: 'relative',
              top: '-17px',
              left: '125px',
              color: active === 990 ? '#ffe86cf0' : 'gray',
            }}
          >
            <span
              style={{
                fontSize: '22px',
                textDecoration: 'line-through',
                color: active === 990 ? '#ffe86cf0' : 'gray',
              }}
            >
              9.9
            </span>
            €
          </span>
          <span>8.6€</span> / mois
        </p>
        <p className="message">Sans engagement</p>
      </div>
    </div>
  );
};
const Step4 = () => {
  const swiper = useSwiper();
  const navigate = useNavigate();
  const [number, setNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [name, setName] = useState('');
  const [focus, setFocus] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [showFormSub, setShowFormSub] = useState(false);

  const isPaymentValid = () => {
    return cvc.length >= 3 && name.length >= 3 && expiry.length >= 5 && number.length >= 16;
  };
  const [typeSubscription, setTypeSubscription] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  const mutationChangeSubscription = useMutation(changeSubscription, {
    onSuccess: (res) => {
      const { client_secret } = res?.latest_invoice?.payment_intent || {};
      if (client_secret) {
        setClientSecret(client_secret);
        setVisibleModal(!visibleModal);
      }
    },

    onError: (err) => {},
  });

  const handleSubscription = () => {
    //return swiper.slideNext();
    const card = {
      number,
      cvc,
      name,
      expiry,
    };
    mutationChangeSubscription.mutate({
      subscriptionSelected: 1,
      card,
    });
  };

  return (
    <div className={'step4Register'}>
      <button
        className="buttonPrev marginTop25 fontSize13p5"
        onClick={() => navigate('/dashboard')}
      >
        <img draggable={false} src={'icon/iconCongratulations.png'} alt={'icon congratulations'} />
        <p className="textImput">Félicitations !</p>
        <p className="textImput fontSize19">
          Vous venez de finaliser votre inscription, à la version découverte du carnet olfactif
          digital.
        </p>
        <p className="textOverProgressBar fontSize16">
          {
            "Cette version vous donne droit à 5 ajouts par section. N'oubliez pas que le carnet olfactif est aussi disponible en version illimitée, avec en prime l'accès à une bibliothèque de contenus exclusifs."
          }
        </p>
      </button>
      <CContainer fluid>
        <CRow className={'marginTop20'}>
          <CCol md={6}>
            <CCol xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
              <CButton onClick={() => navigate('/dashboard')} className={'buttonPay'}>
                Accéder à mon profil
              </CButton>
            </CCol>
            {/* <Cards number={number} expiry={expiry} cvc={cvc} name={name} focused={focus} /> */}
          </CCol>
        </CRow>
      </CContainer>
      {showFormSub && (
        <>
          <CContainer fluid>
            <p className={'infoEvent'} style={{ marginTop: 20 }}>
              Exclusif : promotion de lancement du carnet olfactif.<span>🎉</span>
            </p>
            <CRow className={'marginTop40'}>
              <CCol>
                <CardSelected />
              </CCol>
            </CRow>
            <CRow className={'marginTop20'}>
              {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm
                    userProfil={{
                      email: '',
                    }}
                    confirmParams={`${window.location.origin}/welcome`}
                  />
                </Elements>
              )}
              <CCol md={6}>
                <CCol xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                  <CButton onClick={handleSubscription} className={'buttonPay'}>
                    Continuer
                  </CButton>
                </CCol>
                {/* <Cards number={number} expiry={expiry} cvc={cvc} name={name} focused={focus} /> */}
              </CCol>
            </CRow>
          </CContainer>
        </>
      )}
      <button
        className="buttonPrev marginTop25 fontSize13p5"
        onClick={() => setShowFormSub(!showFormSub)}
        style={{
          backgroundColor: '#22A18A',
          padding: 8,
          borderRadius: 10,
          color: '#fff',
        }}
      >
        {'Souscrire à la version illimitée du carnet olfactif digital'}
        {/*  {"Découvrez l'expérience d’essai du carnet olfactif"} */}{' '}
        <span style={{ fontSize: 14, fontWeight: 'bold' }}></span>
      </button>
    </div>
  );
};
export default Step4;
